import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ServicesSelect from './ServicesSelect';
import Cookies from 'js-cookie'; // Import js-cookie

const useSafariSmoothScrollFix = () => {
  useEffect(() => {
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      document.documentElement.style.scrollBehavior = 'auto';
    }
  }, []);
};

// Modified scroll function
function customSmoothScrollToElement(element, duration = 800) {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const startY = window.pageYOffset;
  const elementY = element.getBoundingClientRect().top + startY;
  const targetY = elementY - (window.innerHeight / 2 - element.offsetHeight / 2);
  
  if (!isSafari && 'scrollBehavior' in document.documentElement.style) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    return;
  }

  // Safari-specific smooth scroll
  const startTime = Date.now();
  
  const animateScroll = () => {
    const currentTime = Date.now();
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1);
    
    // Cubic easing function
    const ease = progress < 0.5 
      ? 4 * progress * progress * progress 
      : 1 - Math.pow(-2 * progress + 2, 3) / 2;

    window.scrollTo(0, startY + (targetY - startY) * ease);

    if (progress < 1) {
      requestAnimationFrame(animateScroll);
    }
  };

  requestAnimationFrame(animateScroll);
}

const getFormattedDate = (dateObj) => {
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getUTCDate()).padStart(2, '0');
  const year = dateObj.getUTCFullYear();
  return `${year}-${month}-${day}`;
};

// Get the default date: the next day if after 8 PM, otherwise today
const getDefaultDate = () => {
  const now = new Date();
  if (now.getHours() >= 20) {
    now.setDate(now.getDate() + 1); // Move to next day
  }
  return getFormattedDate(now);
};
function BarberShopReservation() {
  const [barbers, setBarbers] = useState([]);
  const [sortedBarbers, setSortedBarbers] = useState([]); // New state for sorted barbers
  const [selectedStylist, setSelectedStylist] = useState('');
  const [selectedDate, setSelectedDate] = useState(getDefaultDate());
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [message, setMessage] = useState('');
  const [timeSlots, setTimeSlots] = useState([]);
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [serviceDurations, setServiceDurations] = useState({});
  const [serviceDuration, setServiceDuration] = useState(30); // Track selected service duration
  const [reservations, setReservations] = useState([]); // Track reservations per date/stylist
  const [showModal, setShowModal] = useState(false); // Track modal visibility
  const [currentReservation, setCurrentReservation] = useState(null); // Track selected reservation for modal
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // Track confirmation modal visibility
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');

  const isAuthenticated = !!Cookies.get('isAuthenticated');

  useEffect(() => {
    setSelectedDate(getDefaultDate());
  }, []);

  useEffect(() => {
    if (showModal) {
      // Safari needs a small delay
      const timer = setTimeout(() => {
        const modalElement = document.getElementById('admin-modal');
        if (modalElement) {
          requestAnimationFrame(() => {
            customSmoothScrollToElement(modalElement);
          });
        }
      }, 50); // Increased delay for Safari
      
      return () => clearTimeout(timer);
    }
  }, [showModal]);

  useEffect(() => {
    if (showConfirmationModal) {
      const timer = setTimeout(() => {
        const modalElement = document.getElementById('confirmation-modal');
        if (modalElement) {
          requestAnimationFrame(() => {
            customSmoothScrollToElement(modalElement);
          });
        }
      }, 50);
      
      return () => clearTimeout(timer);
    }
  }, [showConfirmationModal]);


  useEffect(() => {
    const fetchBarbers = async () => {
      try {
        const response = await axios.get('https://alexievs-001-site1.ltempurl.com/api/barbers');
        setBarbers(response.data);
        setSelectedStylist(sortedBarbers[0]?.id);
      } catch (err) {
        console.error('Failed to load barbers', err);
      }
    };

    fetchBarbers();
  }, []);

  useEffect(() => {
    const sortBarbersByAvailableSlots = async () => {
      if (!selectedDate || barbers.length === 0) return;
      try {
        const res = await axios.get(
          `https://alexievs-001-site1.ltempurl.com/api/reservations?date=${selectedDate}`
        );
        // Filter reservations to only include those matching the selected date.
        const reservationsForDay = res.data.filter((reservation) => {
          const reservationDate = new Date(reservation.reservationDateTime)
            .toISOString()
            .split('T')[0];
          return reservationDate === selectedDate;
        });
  
        // Helper: calculate available slots for a given barber.
        const getAvailableSlotCountForBarber = (barberId) => {
          // Start with a fresh copy of the day's time slots.
          const slots = generateTimeSlots().map((slot) => ({ ...slot }));
          // Get all confirmed reservations for this barber.
          const barberReservations = reservationsForDay.filter(
            (reservation) => reservation.barberId == barberId && reservation.isConfirmed
          );
  
          barberReservations.forEach((reservation) => {
            const reservationDate = new Date(reservation.reservationDateTime);
            const reservationTime = reservationDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            });
            const startIndex = slots.findIndex((slot) => slot.time === reservationTime);
            if (startIndex !== -1) {
              // Determine how many slots this reservation takes.
              const slotsNeeded = reservation.service.duration / 30;
              for (let i = 0; i < slotsNeeded; i++) {
                if (startIndex + i < slots.length) {
                  slots[startIndex + i].available = false;
                }
              }
            }
          });
          // Count free slots.
          return slots.filter((slot) => slot.available).length;
        };
  
        // Sort barbers by descending number of available slots (more availability comes first).
        const sorted = [...barbers].sort((a, b) => {
          const availableA = getAvailableSlotCountForBarber(a.id);
          const availableB = getAvailableSlotCountForBarber(b.id);
          return availableB - availableA;
        });
        
        setSortedBarbers(sorted);
        if (!selectedStylist && sorted.length > 0) {
          setSelectedStylist(sorted[0].id);
        }
      } catch (error) {
        console.error('Error sorting barbers by available slots:', error);
      }
    };
  
    sortBarbersByAvailableSlots();
  }, [barbers]);

  const [services, setServices] = useState([]);
  // Fetch services and their durations
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('https://alexievs-001-site1.ltempurl.com/api/services');
        setServices(response.data);

        // Create a map of service IDs and their durations
        const durations = {};
        response.data.forEach((service) => {
          durations[service.id] = service.duration;
        });
        setServiceDurations(durations);
        setSelectedService(response.data[0]?.id); // Set default to the first service
      } catch (err) {
        console.error('Failed to load services', err);
      }
    };

    fetchServices();
  }, []);

  // Update service duration when the selected service changes
  useEffect(() => {
    if (selectedService && serviceDurations[selectedService]) {
      setServiceDuration(serviceDurations[selectedService]);
    }
  }, [selectedService, serviceDurations]);

  // Helper function to generate time slots from 10:00 AM to 8:00 PM with a fixed interval (30 minutes)
  const generateTimeSlots = () => {
    const slots = [];
    let currentTime = new Date();
    currentTime.setHours(10, 0, 0); // Start at 10:00 AM

    const endTime = new Date();
    endTime.setHours(20, 0, 0); // End at 8:00 PM

    while (currentTime < endTime) {
      const hours = String(currentTime.getHours()).padStart(2, '0');
      const minutes = String(currentTime.getMinutes()).padStart(2, '0');
      const timeString = `${hours}:${minutes}`;
      slots.push({ time: timeString, available: true });
      currentTime.setMinutes(currentTime.getMinutes() + 30);
    }

    return slots;
  };

  const updateTimeSlots = (reservations, serviceDuration) => {
    const generatedSlots = generateTimeSlots();
    const updatedSlots = generatedSlots.map((slot, index, slots) => {
      let isTaken = false;

      reservations
        .filter(
          (x) =>
            x.barberId == selectedStylist &&
            x.isConfirmed &&
            new Date(x.reservationDateTime).toISOString().split('T')[0] === selectedDate
        )
        .forEach((reservation) => {
          const reservationDate = new Date(reservation.reservationDateTime);
          const reservationDuration = reservation.service.duration;

          const reservationTime = reservationDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          });

          const startTimeIndex = slots.findIndex((s) => s.time === reservationTime);

          if (startTimeIndex !== -1) {
            const numSlotsRequired = reservationDuration / 30;
            for (let i = 0; i < numSlotsRequired; i++) {
              const slotToCheck = slots[startTimeIndex + i];
              if (slotToCheck) {
                slotToCheck.available = false;
              }
            }
          }
        });

      if (!isTaken && serviceDuration > 30) {
        const numSlotsRequiredForService = serviceDuration / 30;
        for (let i = 0; i < numSlotsRequiredForService; i++) {
          const nextSlot = slots[index + i];
          if (!nextSlot || !nextSlot.available) {
            isTaken = true;
            break;
          }
        }
      }

      if (!slot.available) {
        isTaken = true;
      }

      return { ...slot, available: !isTaken };
    });

    setTimeSlots(updatedSlots);
  };

  const fetchReservations = async () => {
    if (selectedStylist && selectedDate) {
      try {
        const reservationsResponse = await axios.get(
          `https://alexievs-001-site1.ltempurl.com/api/reservations?barberId=${selectedStylist}&date=${selectedDate}`
        );
        const reservations = reservationsResponse.data;
        setReservations(reservations);
        updateTimeSlots(reservations, serviceDuration);
      } catch (err) {
        console.error('Failed to fetch reservations', err);
      }
    }
  };

  useEffect(() => {
    if (selectedDate && !isRestDay()) {
      fetchReservations();
    }
  }, [selectedDate, selectedStylist, serviceDuration]);

  const isRestDay = () => {
    const date = new Date(selectedDate);
    const dayOfWeek = date.getUTCDay();

    if (selectedStylist == 1 && dayOfWeek === 1) return true;
    if (selectedStylist == 2 && dayOfWeek === 0) return true;

    return false;
  };

  const handleStylistChange = (stylistId) => {
    setSelectedStylist(stylistId);
    setSelectedTime('');
    setCurrentReservation(null);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleReservationClick = (slot) => {
    if (slot.available) {
      setSelectedTime(slot.time);
    } else if (isAuthenticated) {
      const reservation = reservations.find((res) => {
        const reservationDate = new Date(res.reservationDateTime).toISOString().split('T')[0];
        const isSameDate = reservationDate === selectedDate;

        const reservationTime = new Date(res.reservationDateTime).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
        const isSameTime = reservationTime === slot.time;
        const isSameBarber = res.barberId == selectedStylist;
        return isSameTime && isSameDate && isSameBarber && res.isConfirmed;
      });

      if (reservation) {
        setCurrentReservation(reservation);
        setShowModal(true);
      }
    }
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleEmailChange = (event) => {
    setCustomerEmail(event.target.value);
  };

  const handleNameChange = (event) => {
    setCustomerName(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setCustomerPhone(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedDate && selectedService && selectedStylist && selectedTime && !isRestDay()) {
      try {
        const fullDateTime = `${selectedDate}T${selectedTime}:00`;

        const reservationData = {
          customerName,
          phoneNumber: customerPhone,
          email: customerEmail,
          reservationDateTime: fullDateTime,
          serviceId: selectedService,
          barberId: selectedStylist,
        };
        const loginRequest = isAuthenticated
          ? { username: 'Admin', password: 'Admin!' }
          : { username: 'no', password: 'no' };

        await axios.post('https://alexievs-001-site1.ltempurl.com/api/reservations', reservationData, {
          headers: { 'Content-Type': 'application/json' },
          params: loginRequest,
        });

        setCustomerName('');
        setCustomerEmail('');
        setCustomerPhone('');
        setSelectedTime('');

        if (isAuthenticated) {
          setConfirmationModalMessage('Успешно създадохте резервация.');
        } else {
          setConfirmationModalMessage('Моля потвърдете резервацията по имейл, за да бъде запазена.');
        }

        setShowConfirmationModal(true);

        setTimeout(() => {
          requestAnimationFrame(() => {
            const modalElement = document.getElementById('confirmation-modal');
            if (modalElement) {
              customSmoothScrollToElement(modalElement);
            }
          });
        }, 0);
        

        fetchReservations();
      } catch (error) {
        console.error('Failed to create reservation', error.response?.data || error.message);
        setMessage('Неуспешен опит за резервация.');
      }
    }
  };

  const newdate = getDefaultDate();

  const closeModal = () => {
    setShowModal(false);
    setCurrentReservation(null);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteReservation = async () => {
    try {
      await axios.delete(`https://alexievs-001-site1.ltempurl.com/api/reservations/${currentReservation.id}`);
      setShowModal(false);
      setCurrentReservation(null);
      fetchReservations();
    } catch (error) {
      console.error('Failed to delete reservation:', error);
    }
  };

  return (
    <div className="text-red-800 max-w-full flex justify-center overflow-hidden py-4">
      <form className="flex text-2xl flex-col items-center w-full px-4" onSubmit={handleSubmit}>
        <div className="flex py-4 flex-col gap-4 w-full px-3">
          <div className="flex flex-col gap-4 items-center w-full">
            <div className="font-bold w-full">Фризьор & Услуга:</div>
            <select
              required
              value={selectedStylist}
              onChange={(e) => handleStylistChange(e.target.value)}
              className="border w-full border-red-800 bg-neutral-100 rounded-lg p-2 transition duration-300 hover:shadow-lg focus:shadow-outline"
            >
              {sortedBarbers.map((barber) => (
                <option key={barber.id} value={barber.id}>
                  {barber.name}
                </option>
              ))}
            </select>

            <ServicesSelect
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              className="w-full"
            />
          </div>
          <div className="flex gap-4 flex-col items-center w-full">
            <div className="label text-left font-bold w-full">Дата:</div>
            <input
              min={newdate}
              className="bg-neutral-100 border w-full border-red-800 rounded-lg p-2 transition duration-300 hover:shadow-lg focus:shadow-outline"
              required
              type="date"
              value={selectedDate || newdate}
              onChange={handleDateChange}
            />
            {isRestDay() && selectedStylist == 1 && (
              <p className="text-red-800 text-xl text-center py-4">
                Понеделник е почивен ден за Емо! Моля, изберете друга дата.
              </p>
            )}
            {isRestDay() && selectedStylist == 2 && (
              <p className="text-red-800 text-xl text-center py-4">
                Неделя е почивен ден за Боян! Моля, изберете друга дата.
              </p>
            )}
            <div className="label font-bold w-full">Час:</div>
            <input
              value={selectedTime || 'Не е избран'}
              className="bg-neutral-300 text-center w-full"
              disabled
            />
          </div>
        </div>
        {!isRestDay() && (
          <div className="select-container w-full">
            {timeSlots.length > 0 ? (
              <div className="w-full flex flex-wrap gap-2 justify-center text-xl nav">
                {timeSlots.map((slot) => (
                  <button
                    key={slot.time}
                    disabled={!slot.available && !isAuthenticated}
                    className={`custom-option w-full sm:w-auto ${
                      selectedTime === slot.time
                        ? 'bg-red-800 bg-opacity-60'
                        : 'bg-red-800 bg-opacity-20'
                    } rounded-md p-2 hover:bg-opacity-80 transition duration-300 ${
                      !slot.available ? 'bg-gray-700 opacity-50' : ''
                    }`}
                    onClick={() => handleReservationClick(slot)}
                    type="button"
                  >
                    {slot.time}
                  </button>
                ))}
              </div>
            ) : (
              <p className="w-full text-center">Няма налични часове за избраната дата.</p>
            )}
          </div>
        )}
        {message && <p className="max-w-xl text-center py-4 w-full">{message}</p>}
        <div className="flex flex-col gap-2 w-full py-4 justify-between">
          <div className="font-bold">Име:</div>
          <input
            type="text"
            value={customerName}
            onChange={handleNameChange}
            className="bg-neutral-100 border w-full border-red-800 rounded-lg p-2 transition duration-300 hover:shadow-lg focus:shadow-outline"
            required
          />

          <div className="font-bold">Имейл: </div>
          <input
            type="email"
            value={customerEmail}
            onChange={handleEmailChange}
            className="bg-neutral-100 border w-full border-red-800 rounded-lg p-2 transition duration-300 hover:shadow-lg focus:shadow-outline"
            required
          />

          <div className="font-bold">Телефон:</div>
          <input
            type="tel"
            value={customerPhone}
            onChange={handlePhoneChange}
            className="bg-neutral-100 border w-full border-red-800 rounded-lg p-2 transition duration-300 hover:shadow-lg focus:shadow-outline"
            required
          />

          <button
            className="bg-red-800 text-neutral-300 w-full px-6 py-3 rounded-lg shadow-lg transform transition duration-300 hover:bg-red-700 hover:scale-105 hover:shadow-xl active:scale-95 active:bg-red-900 focus:outline-none focus:ring-4 focus:ring-red-500"
            type="submit"
          >
            Резервирай
          </button>
        </div>
        {showModal && currentReservation && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div
              id="admin-modal"
              className="bg-white text-black rounded-2xl shadow-xl p-8 max-w-md w-full border border-red-800"
            >
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-2xl font-bold text-red-800">Детайли за резервация</h3>
                <button
                  onClick={closeModal}
                  className="text-red-800 text-2xl font-bold hover:text-red-900 transition duration-200"
                >
                  &times;
                </button>
              </div>
              <div className="space-y-4">
                <p className="text-lg">
                  <strong className="text-red-800">Име:</strong> {currentReservation.customerName}
                </p>
                <p className="text-lg">
                  <strong className="text-red-800">Телефон:</strong> {currentReservation.phoneNumber}
                </p>
                <p className="text-lg">
                  <strong className="text-red-800">Имейл:</strong> {currentReservation.email}
                </p>
                <p className="text-lg">
                  <strong className="text-red-800">Услуга:</strong> {currentReservation.service?.name}
                </p>
                <p className="text-lg">
                  <strong className="text-red-800">Дата и час:</strong>{' '}
                  {new Date(currentReservation.reservationDateTime).toLocaleDateString()},{' '}
                  {new Date(currentReservation.reservationDateTime).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })}
                </p>
              </div>
              <div className="flex justify-between gap-4 mt-6">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleDeleteReservation();
                  }}
                  className="bg-red-500 text-white px-6 py-2 rounded-lg shadow-md hover:bg-red-600 transition duration-300"
                >
                  Изтрий
                </button>

                <button
                  onClick={closeModal}
                  className="bg-gray-400 text-white px-6 py-2 rounded-lg shadow-md hover:bg-gray-500 transition duration-300"
                >
                  Затвори
                </button>
              </div>
            </div>
          </div>
        )}

        {showConfirmationModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div
              id="confirmation-modal"
              className="bg-white text-black rounded-lg shadow-lg p-8 max-w-sm w-full"
            >
              <h3 className="text-xl font-semibold mb-4">Потвърждение</h3>
              <p>{confirmationModalMessage}</p>
              <div className="flex justify-end mt-4">
                <button
                  onClick={closeConfirmationModal}
                  className="bg-red-800 text-white px-4 py-2 rounded-md hover:bg-red-900 transition duration-200"
                >
                  Затвори
                </button>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

export default BarberShopReservation;
